.attorney-container {
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 2em 2fr 1fr;
  /* grid-auto-rows: minmax(10px, auto); */
  justify-items: stretch;
  align-items: start;
  grid-template-areas: 
  ". main-head main-head . secondary-head ." 
  ". stripe stripe . secondary-stripe ." 
  ". main-pic main-contact . secondary ." 
  ". main-bio main-bio . secondary ." 
  ". main-transactions main-transactions . secondary ."
  ". main-probono main-probono . secondary .";
}

.attorney-container ul {
  list-style: none;
}

.attorney-container li::before {
  content: "\25AA";
  color: #f60;
  display: inline-block;
  font-size: 1.5em;
  width: 1.0em;
  margin-left: -1em
}
/* Main */

.so-attorney-grid-main-head {
  grid-area: main-head;
  color: #605f5f;
  font-family: Garamond, serif;
  font-size: 30px;
  padding: 15px;
  min-height: 33.6px;
  font-weight: bold;
}

.so-attorney-grid-main-head-stripe {
  grid-area: main-head-stripe;
}

.so-attorney-grid-stripe {
  grid-area: stripe;
  height: 10px;
  background: #f60;
  border-right: 1px solid black;
  border-left: 1px solid black;
}

.so-attorney-grid-main-pic {
  grid-area: main-pic;
}

.so-attorney-grid-main-contact {
  grid-area: main-contact;
  /* background-color: #f2f2f2; */
  background-color: #e8efff;
  margin-right: 15px;
  margin-top: 15px;
  line-height: 1.42857143;
  color: #333;
  font-family: Garamond, serif;
  font-size: 16px;
  padding: 10px;
  box-shadow: 3px 3px 5px 1px rgba(0, 0, 0, .2);
  /* border: 1px solid rgb(187, 75, 1); */
  border: 1px solid black;
}
/* 
.so-attorney-grid-main-contact h4 {
  font-family: Garamond, serif;
  font-size: 22px;
  color: #333;
  font-weight: bold;
  padding: 0px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 15px;
  margin-left: 0px;
} */

.so-attorney-grid-main-bio {
  grid-area: main-bio;
}

.so-attorney-grid-main-transactions {
  grid-area: main-transactions;
}

.so-attorney-grid-main-probono {
  grid-area: main-probono;
}

.so-attorney-grid-main-overall {
  grid-area: 1/2/7/4;
  align-self: stretch;
  border: 1px solid black;
  background: white;
}

.so-attorney-grid-main-pic,
/* .so-attorney-grid-main-contact, */

.so-attorney-grid-main-bio, .so-attorney-grid-main-transactions, .so-attorney-grid-main-probono, .so-attorney-grid-secondary {
  /* background: #fff; */
  font-family: Garamond, serif;
  font-size: 16px;
  padding: 15px 15px 0px 15px;
}

.so-attorney-grid-main-pic h4,
/* .so-attorney-grid-main-contact h4, */

.so-attorney-grid-main-bio h4, .so-attorney-grid-main-contact h4, .so-attorney-grid-main-transactions h4, .so-attorney-grid-main-probono h4, .so-attorney-grid-secondary h4 {
  font-family: Garamond, serif;
  font-size: 22px;
  color: #333;
  font-weight: bold;
  padding: 0px;
  margin: 0px 0px 15px 0px;
}

/* Secondary */

.so-attorney-grid-secondary-head {
  grid-area: secondary-head;
  color: #605f5f;
  font-family: Garamond, serif;
  font-size: 30px;
  padding: 15px;
  min-height: 33.6px;
  font-weight: bold;
  border-top: 1px solid black;
  border-right: 1px solid black;
  border-left: 1px solid black;
  background: white;
}

.so-attorney-grid-secondary-stripe {
  grid-area: secondary-stripe;
  height: 10px;
  background: #f60;
  border-right: 1px solid black;
  border-left: 1px solid black;
}

.so-attorney-grid-secondary {
  grid-area: secondary;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  border-left: 1px solid black;
  background: white;
  padding-bottom: 10px;
}

/* .so-attorney-grid-secondary-overall {
  grid-area: 1/5/6/6;
  align-self: stretch;
  background: white;
} */

/* .so-attorney-grid-main {
  grid-area: main;
}

.so-attorney-grid-secondary {
  grid-area: secondary;
}

.so-attorney-grid-subgrid1 {
  grid-area: 2/2/3/3
}
.so-attorney-grid-subgrid2 {
  grid-area: 3/2/4/3
}
.so-attorney-grid-subgrid3 {
  grid-area: 4/2/5/3
}
.so-attorney-grid-subgrid4 {
  grid-area: 5/2/6/3
}
*/

@media all and (max-width: 1000px) {
  .attorney-container {
    grid-template-columns: 1fr 8fr 1fr;
    grid-template-areas: 
    ". main-head  ." 
    ". stripe  ." 
    ". main-pic  ." 
    ". main-contact." 
    ". secondary  ." 
    ". main-bio ." 
    ". main-transactions ."
    ". main-probono .";
  }
  .so-attorney-grid-secondary-head, .so-attorney-grid-secondary-stripe, .so-attorney-grid-secondary-overall {
    display: none;
  }
  .so-attorney-grid-main-overall {
    grid-area: 1/2/9/3;
  }
  .so-attorney-grid-secondary {
    border: none;
    border-right: 1px solid black;
    border-left: 1px solid black;
  }
  .so-attorney-grid-main-contact {
    grid-area: main-contact;
    background-color: transparent;
    margin-right: 15px;
    margin-top: 15px;
    line-height: 1.42857143;
    color: #333;
    font-family: Garamond, serif;
    font-size: 16px;
    padding: 10px;
    box-shadow: none;
    border: none;
  }
}

@media all and (max-width: 500px) {
  .attorney-container {
    grid-template-columns: 1em 16fr 1em;
  }
}