.stripedbox-container {
  border: 1px solid black;
  margin-bottom: 20px;
}

.stripedbox-header {
  background: #fff;
  color: #605f5f;
  font-family: Garamond, serif;
  font-size: 30px;
  padding: 15px;
  min-height: 33.6px;
  font-weight: bold;
}

.stripedbox-stripe {
  background: #f60;
  height: 10px;
}

.stripedbox-content {
  background: #fff;
  font-family: Garamond, serif;
  font-size: 16px;
  padding: 15px;
}

.stripedbox-content h4 {
  font-family: Garamond, serif;
  font-size: 22px;
  color: #333;
  font-weight: bold;
}

.stripedbox-content a {
  color: inherit; /* blue colors for links too */
  text-decoration: none; /* no underline */
  text-decoration: underline rgba(255, 102, 0, 0.425);
  cursor: pointer;
}
.stripedbox-content a:hover {
  color: #f60;
}


.stripedbox-content ul {
  list-style: none;
  margin-top: .4em;
  margin-bottom: 0em;
  padding-left: 4em;
}

.stripedbox-content li::before {
  content: "\25AA";
  color: #f60;
  display: inline-block;
  font-size: 1.5em;
  width: 1.5em;
  margin-left: -1em
}

