.header-container {
  background: black;
  padding: 2px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-graphic-phone {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.header-graphic {
  background-image: url("../images/AccentGraphic.png");
  background-repeat: no-repeat;
  /* background-size: cover; */
  width: 72px;
  height: 32px;
  background-position: center center;
  min-width: 72px;
  /* background-color: red;  */
}

.header-graphic>.right {
  margin-left: auto;
}

.header-menu-overall-container {
  flex: 1 1 auto;
  /* background: green; */
  /* display: inline-block; */
}

.header-menu-overall-container-phone {
  flex: 1 1 auto;
  /* width: 0%; */
}

.navbutton {
  background-color: #f60;
  color: #fff;
  /* flex: 1 1 auto; */
  /* position: relative;
  float: right; */
  margin: 3px 8px;
  margin-left: auto;
  padding: 9px 10px;
  /* margin-top: 8px;
  margin-bottom: 8px; */
  /* background-color: transparent; */
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
}

@media all and (max-width: 800px) {
  .header-container {
    flex-direction: row;
    /* justify-content: center; */
  }
  /* .header-graphic {
    flex: 1 1 100%;
    background: purple;
    width: 100%;
  } */
  .header-menu-overall-container, .header-menu-overall-container-phone {
    flex: 1 1 100%;
    /* background: purple; */
  }
}

@media all and (max-width: 600px) {
  .header-graphic.right {
    display: none;
  }
}

@media all and (max-width: 415px) {
  .header-graphic.left {
    display: none;
  }
  .header-graphic-phone .header-graphic.left {
    display: inline-block;
  }
  .header-menu-overall-container {
    display: none;
  }
}

@media all and (min-width: 415px) {
  .header-graphic-phone {
    display: none;
  }
  .header-menu-overall-container-phone {
    display: none;
  }
}