
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  height: 500px;
  margin: 20px 0px 20px 0px;
  /* overflow: hidden; */
}

.embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border: 0;
}