.practices-container {
  display: grid;
  grid-template-columns: 1fr 6fr 2em 2fr 1fr;
  /* grid-template-rows: auto 10px auto; */
  justify-items: stretch;
  align-items: start;
  grid-template-areas: 
  ". main . graphic ." 
  ". main . graphic ." 
  ". main . graphic .";
}

.notices-container {
  display: grid;
  grid-template-columns: 1fr 8fr 1fr;
  /* grid-template-rows: auto 10px auto; */
  justify-items: stretch;
  align-items: start;
  grid-template-areas: 
  ". main . " 
  ". main . " 
  ". main . ";
}

.so-grid-main {
  /* border: 1px solid black; */
  grid-area: main;
  /* background: blue; */
}

.so-grid-graphic {
  /* border-top: 1px solid black; */
  grid-area: graphic;
  /* background: blue; */
}

@media all and (max-width: 1000px) {
  .practices-container {
    grid-template-columns: 1fr 8fr 1fr;
    grid-template-areas: 
    "left main  right" 
    "left main  right" 
    "left main  right";
  }
  .so-grid-graphic, .so-grid-graphic-stripe, .so-grid-graphic-frost {
    display: none;
  }
}

