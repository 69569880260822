.App {
  height: 100%;
}

.content-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: rgba(128, 128, 128, 0.103);
}

.content-logo {
  flex: 0 0 auto;
  order: 2;
  margin-top: 20px;
}

.content-header {
  flex: 0 0 auto;
  order: 1;
}

.content-main {
  flex: 1 0 auto;
  margin-top: 20px;
  order: 3;
}

.content-footer {
  flex: 0 0 auto;
  margin-top: auto;
  order: 4;
}

@media all and (max-width: 400px) {
  .content-logo {
    order: 0;
    margin-top: 0px;
    padding: 5px 0px;
    background: white;
  }
}