.so-header-middle-container {
  /* border: solid 1px red; */
  /* padding-left: 10px; */
  line-height: 1em;
  flex: 10 1 auto;
  display: flex;
  justify-content: center;
  /* align-self: flex-end; */
  align-items: flex-end; 
}

.so-header-middle-container > .so-header-middle {
  /* border: solid 1px green; */
  /* max-width: 500px; */
  padding-left: 1em;
  padding-right: 1em;
  flex: 1 1 auto;
  max-width: 500px;
  min-width: 150px;
  cursor: pointer;
  /* align-self: flex-end; */
  /* justify-content: center; */
}
