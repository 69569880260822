.stripedimage-frost {
  background: rgba(255, 255, 255, .7);
  font-family: Garamond, serif;
  font-size: 30px;
  color: rgba(255, 255, 255, .0);
  /* height: 1rem; */
  /* position: absolute; */
  /* left: 0px;
  top: 0px; */
  z-index: 1;
  min-height: 33.6px;
  /* width: 100%; */
  padding: 15px 0px;
  /* margin-top: 1px;
  margin-left: 1px;
  margin-right: 1px; */
  /* border-top: 1px solid black;
  border-right: 1px solid black;
  border-left: 1px solid black; */
}

.stripedimage-stripe {
  background: #f60;
  height: 10px;
  /* margin-left: 1px;
  margin-right: 1px; */
  /* border-right: 1px solid black;
  border-left: 1px solid black; */
  /* position: absolute; */
  /* left: 0px;
  top: 63px; */
  z-index: 10;
  /* width: 100%; */
}

.stripedimage-container img {
  /* position: absolute;
  left: 0px;
  top: 0px;
  z-index: -10; */
  width: 100%;
  /* margin-left: -1px; */
  /* margin-right: -1px; */
  /* border-bottom: 1px solid black; */
  /* border-right: 1px solid black; */
}
