.attorneys-container {
  display: grid;
  grid-template-columns: 1fr 6fr 2em 2fr 1fr;
  justify-items: stretch;
  align-items: start;
  margin-bottom: 15px;
  grid-template-areas: ". main-head . secondary-head ." ". stripe  . secondary-stripe ." ". attorneys  . secondary .";
}

.attorneys-container ul {
  list-style: none;
}

.attorneys-container li::before {
  content: "\25AA";
  color: #f60;
  display: inline-block;
  font-size: 1.5em;
  width: 1.0em;
  margin-left: -1em
}

/* Main */


.so-attorneys-grid-main-head {
  background: white;
  grid-area: main-head;
  color: #605f5f;
  font-family: Garamond, serif;
  font-size: 30px;
  padding: 15px;
  margin: 1px 1px 0px 1px;
  min-height: 33.6px;
  font-weight: bold;
  z-index: 2;
}

.so-attorneys-grid-stripe {
  grid-area: stripe;
  height: 10px;
  margin: 0px 1px 0px 1px;
  background: #f60;
  z-index: 2;
  /* border-right: 1px solid black;
  border-left: 1px solid black; */
}

.so-attorneys-grid-attorneys {
  background: white;
  grid-area: attorneys;
  /* background: #fff; */
  font-family: Garamond, serif;
  font-size: 16px;
  margin: 0px 1px 1px 1px;
  padding: 15px 15px 0px 15px;
  z-index: 2;
}

.so-attorneys-grid-attorneys h4 {
  font-family: Garamond, serif;
  font-size: 22px;
  color: #333;
  font-weight: bold;
  padding: 0px;
  margin: 0px 0px 15px 0px;
}


.so-attorneys-grid-main-overall {
  grid-area: 1/2/6/3;
  border: 1px solid black;
  height: 100%;
  /* background: blue; */
  z-index: 1;
}

/* Secondary */

.so-attorneys-grid-secondary-head {
  grid-area: secondary-head;
  /* color: #605f5f; */
  /* font-family: Garamond, serif; */
  /* font-size: 30px; */
  /* padding: 15px; */
  height: 100%;
  /* min-height: 33.6px; */
  /* font-weight: bold; */
  /* border-top: 1px solid black; */
  /* border-right: 1px solid black; */
  /* border-left: 1px solid black; */
  background: rgba(255, 255, 255, .7);
}

.so-attorneys-grid-secondary-stripe {
  grid-area: secondary-stripe;
  height: 10px;
  background: #f60;
  /* border-right: 1px solid black;
  border-left: 1px solid black; */
}

.so-attorneys-grid-secondary {
  grid-area: 1/4/6/5;
  z-index: -1;
  height: 100%;
  /* align-self: flex-start; */
}

.so-attorneys-grid-secondary-border {
  grid-area: 1/4/6/5;
  z-index: 100;
  height: 100%;
  /* border-top: 1px solid black; */
  /* border-bottom: 1px solid red; */
}

.so-attorneys-grid-secondary>img {
  /* object-fit:cover; */
  width: 100%;
  /* height: 100%; */
  /* max-height: auto; */
  /* max-width: 100%; */
  padding: 0px;
  /* border-left: 1px solid black; */
  /* border-right: 1px solid black; */
  /* border-bottom: 1px solid black; */
  /* border: 1px solid black; */
}

/* .so-attorneys-grid-secondary-overall {
  grid-area: 1/4/6/5;
  padding: 1px;
  z-index: 100;
  align-self: stretch;
  border: 1px solid green;
} */

@media all and (max-width: 1000px) {
  .attorneys-container {
    grid-template-columns: 1fr 8fr 1fr;
    grid-template-areas: ". main-head  ." ". stripe  ." ". main-pic  ." ". attorneys.";
  }
  .so-attorneys-grid-secondary, .so-attorneys-grid-secondary-border, .so-attorneys-grid-secondary-head, .so-attorneys-grid-secondary-stripe, .so-attorneys-grid-secondary-overall {
    display: none;
  }
  .so-attorneys-grid-main-overall {
    grid-area: 1/2/6/3;
  }
  .so-attorneys-grid-secondary {
    border: none;
  }
}

@media all and (max-width: 500px) {
  .attorneys-container {
    grid-template-columns: 1em 16fr 1em;
  }
}