.ReactCollapse--collapse {
  transition: height 300ms;
}

.collapsible-component-container {
  padding-bottom: 15px;
}

.collapse-component-heading {
  display: flex;
  cursor: pointer;
}

.collapse-component-heading-item {
  font-family: Garamond, serif;
  font-size: 22px;
  color: #333;
  font-weight: bold;
  transition: .3s ease;
}

.collapse-component-heading-item:hover {
  color: rgb(189, 75, 0);
}

.collapse-component-heading-item.icon {
  padding-right: 20px;
}

.collapse-component-content ul {
  list-style: none;
}
.collapse-component-content > ul {
  margin-top: .4em;
  margin-bottom: 0em;
  padding-left: 4em;
}

.collapse-component-content li::before {
  content: "\25AA";
  color: #f60;
  display: inline-block;
  font-size: 1.5em;
  width: 1.5em;
  margin-left: -1em
}

.collapse-component-content li.circle::before {
  content: "\2022";
  color: #f60;
  display: inline-block;
  font-size: 2em;
  padding-left: 0.2em;
  width: 1.2em;
  margin-left: -1em
}