
.footer-container {
  background: black;
  color: white;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 5px 0px;
  flex-wrap: wrap;
  /* font-size: 16px; */
  font-family: Garamond, serif;
  font-size: 1em;
}

.footer-left {
  flex: 1 1 auto;
  background: red;
}

.footer-menu-overall-container {
  flex: 1 1 600px;
  /* background: blue; */
  /* min-width: 760px; */
}

.footer-menu-overall-container ul {
  display: flex;
  list-style: none;
  align-items: flex-start;
  justify-content: center;
  padding: 0px;
  margin: 0px;
}

.footer-menu-overall-container ul  li  {
  padding: 0px 5px 0px 5px;
  margin: 0px;
  border-right: 1px dotted white;
}
.footer-menu-overall-container ul > li {
  border-right: 0px;
}

.footer-right {
  flex: 1 0 155px;
  /* background: green; */
  display: flex;
  /* align-items: flex-end; */
  justify-content: flex-start;
  /* transition: .9s ease; */

}


.footer-right-item {
  /* flex: 1 1 auto; */
  display: flex;
  margin-right: 20px;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;
  transition: .4s ease;
  /* background: grey; */
}

.footer-right-item-top {
  /* background: blue; */
  flex: 0 0  auto;
}

.footer-right-item-top-picture {
  transition: .3s ease;

}

.footer-right-item-bottom {
  /* background: yellow; */
  padding-left: 3px;
  flex: 1 1 auto;
}

.footer-right-item:hover {
  color: #f60
}

.footer-nav {
  text-decoration: none;
  color: white;
  transition: .4s ease;

}

.footer-nav:hover {
  color: rgb(51, 255, 0);
  border-color: rgb(51, 255, 0);
  flex: 0 1 auto;
}

.footer-nav-active {
  text-decoration: none;
  color: #f60;
}

.footer-right-item a {
  text-decoration: none;
  color: inherit;
  vertical-align: top;
}
@media all and (max-width: 800px) {
  .footer-right {
    justify-content: center;
  }
  .footer-right-item {
    justify-content: center;
  }
  .footer-right-item-bottom {
    flex: 0 1 auto;
  }
}
@media all and (max-width: 755px) {
  .footer-container {
    font-size: .7em;
  }
  .footer-menu-overall-container ul {
    flex-wrap: wrap;
  }
}