.header-menu-container {
  /* flex: 1 1 auto; */
  /* background: blue; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-menu-container ul {
  /* border: 1px solid rgb(212, 20, 20);  */
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.header-menu-container li {
  /* max-width: 250px; */
  flex: 0 1 auto;
  padding: 15px 15px;
  /* background: green; */
  font-family: Garamond, serif;
  font-size: 0.83em;
  font-weight: bolder;
  margin: 0px 2px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 5px solid black;
  text-align: center;
  transition: .3s ease;
  /* border-left: 5px solid black; */
}

.header-menu-container li.active {
  border-right: 5px solid rgb(255, 102, 0);
  border-left: 5px solid rgb(255, 102, 0);
}

.header-menu-container li:hover {
  color: rgb(51, 255, 0);
  border-left-color: rgb(51, 255, 0);
  border-right-color: rgb(51, 255, 0);
}

.main-nav {
  color: white;
}

.header-menu-container li:hover .main-nav {
  color: rgb(51, 255, 0);
  border-color: rgb(51, 255, 0);
  flex: 0 1 auto;
}

.main-nav-active {
  color: rgb(255, 102, 0);
}

@media all and (max-width: 800px) {
  .header-menu-container ul {
    flex-wrap: wrap;
    justify-content: start;
  }
  /* .header-menu-container li { flex: 0 1 100%; }  */
  .main-nav {
    flex: 0 1 50%;
  }
  .header-container {
    flex-wrap: wrap;
  }
}

@media all and (max-width: 400px) {
  /* .header-container {justify-content: center;} */
  .header-menu-container ul {
    justify-content: center;
    flex-direction: column;
  }
  .main-nav {
    flex: 0 1 100%;
  }
}

@media print {
  .no-print, .no-print * {
    display: none !important;
  }
}